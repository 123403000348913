import React, { useState, useEffect } from 'react';
import CustomUpload from 'Shared/CustomUpload';
import { T } from 'components/Translations';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationInput } from '../../../../Shared/Form/validation';

export function SSOSettings(props) {
    const {onChangeData, dataItem, setDataItem, validation } = props;

    const [manifest, setManifest] = useState({});

    useEffect(() => {
        let manifest_ = Object.fromEntries(Object.entries(manifest).filter(([_, v]) => v.length !== 0));

        if (dataItem) {
            setDataItem({ ...dataItem, settings: { ...dataItem.settings, manifest: manifest_ } });
        }
    }, [manifest, setDataItem]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className='section_group'>
                <div className='sections '>
                    <FormField>
                        {
                            <label checkbox=''><input type='checkbox' name='settings.hide_serial_number_search'
                                onChange={onChangeData}
                                defaultChecked={dataItem.settings?.hide_serial_number_search} />
                                <box><check></check></box><text><T>text.hide_serial_number_search</T></text>
                            </label>
                        }
                    </FormField>
                </div>
                <div className='sections '>
                    <FormField>
                        {
                            <label checkbox=''><input type='checkbox' name='settings.hide_demande_garantie'
                                onChange={onChangeData}
                                defaultChecked={dataItem.settings?.hide_demande_garantie} />
                                <box><check></check></box><text><T>text.hide_demande_garantie</T></text>
                            </label>
                        }
                    </FormField>
                </div>
            </div>
            <div className='section_group'>
                <div className='sections '>
                    <FormField label="text.short_name">
                        <ValidationInput
                            validation={validation}
                            name='settings.manifest.short_name'
                            type='text'
                            value={dataItem.settings.manifest?.short_name || ''}
                            onChange={onChangeData} // NOSONAR
                            copy
                        />
                    </FormField>
                    <FormField label="text.name">
                        <ValidationInput
                            validation={validation}
                            name='settings.manifest.name'
                            type='text'
                            value={dataItem.settings.manifest?.name || ''}
                            onChange={onChangeData} // NOSONAR
                        />
                    </FormField>

                </div>
                <div className='sections '>
                    <FormField label="text.cookiebot">
                        <ValidationInput
                            validation={validation}
                            name='settings.cookiebot'
                            type='text'
                            value={dataItem.settings.cookiebot || ''}
                            onChange={onChangeData} // NOSONAR
                            placeHolder={dataItem.univers_cookiebot || ''}
                        />
                    </FormField>
                    <FormField label="text.datalayer">
                        <ValidationInput
                            validation={validation}
                            name='settings.datalayer'
                            type='text'
                            value={dataItem.settings.datalayer || ''}
                            onChange={onChangeData} // NOSONAR
                            placeHolder={dataItem.univers_datalayer || ''}
                        />
                    </FormField>

                </div>
            </div>
            <div>
                <div className='section_group' style={{ width: 400, paddingLeft: 10 }}>
                    <FormField label="text.favicon">
                        <CustomUpload
                            imageField='favicon'
                            dataItem={dataItem.settings.manifest || ''}
                            setDataItem={setManifest}
                            isLocalUpload={true}
                            isManifest
                            maxHeight={32}
                            maxWidth={32}
                            acceptTypes={['.ico']}
                        />
                    </FormField>
                    <FormField label="text.icon">
                        <CustomUpload
                            imageField='icon512'
                            dataItem={dataItem.settings.manifest || ''}
                            setDataItem={setManifest}
                            isLocalUpload={true}
                            maxHeight={512}
                            minHeight={512}
                            maxWidth={512}
                            minWidth={512}
                            isManifest
                            additionalField={{ fieldName: 'icon192', height: 192, width: 192 }}
                            acceptTypes={['.png']}
                        />
                    </FormField>
                    <FormField label="text.logo">
                        <CustomUpload
                            imageField='logo'
                            dataItem={dataItem.settings.manifest || ''}
                            setDataItem={setManifest}
                            isLocalUpload={true}
                            isManifest
                            acceptTypes={['.png', '.svg']}
                        />
                    </FormField>
                </div>
            </div>
        </div>
    );
};