import React, { useCallback, useEffect, useRef } from 'react';
import { AppPages } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { connect } from 'react-redux';
import { T } from '~/components/Translations.js';
//import { showLoader, hideLoader } from '../Shared/Loader' // NOSONAR



//const loaderName = 'LOGIN_LOADER'  // NOSONAR

function getParams() {
    return new URLSearchParams(window.location.search);
}

/**
 * the Login Component where user authorizati"on occurs
 * @exports Login
 */
function Login(props) {
    const { isLoggedIn, history } = props;
    const query = getParams();
    const redirectUrl = query.get("redirectUrl") || "";
    const ref = useRef(false);

    useEffect(() => {
        ref.current = true;
        return () => {
            ref.current = false;
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoggedIn) {
            if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
                history.push(redirectUrl);
            }
            else {
                history.push(Project.getPageUrl(AppPages.Home));
            }

        }
    }, [isLoggedIn]);// eslint-disable-line react-hooks/exhaustive-deps

    const doConnect = useCallback(() => {
        if (ref.current) {
            const callback = `${window.location.origin}/wso/callback`;

            let headers = { "Content-Type": "application/x-www-form-urlencoded" };
            const fetchOptions = { 
                method: "POST",
            };
            let params = {
                ...fetchOptions, 
                headers: headers
            };

            fetch(`${process.env.REACT_APP_API_TOKEN_URL ?? window.location.origin}/api/getClient`, params)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response){
                    //debugger
                    const client_id = response.client_id;
                    const url = `${process.env.REACT_APP_API_URL}/oauth2/authorize?scope=openid&state=&response_type=code&redirect_uri=${callback}&client_id=${client_id}`;
                    window.location.href = url;
                    
                }
                Project.setLocalStorage("isLoadingAppToken", false, 30);
            })
            .catch((error) =>{
                console.log(error);
            });

        }
    }, [])

    if (!props.isLoggedIn) {
        return <view intro="">
                <login>
                    <cont>
                        <wrap id="container" multiple="multiple">
                            <info>
                                <text className="info"><b><T>text.sign_in</T></b></text>
                                <text className="info"><b><T>text.use_your_account</T></b></text>
                            </info>
                            <toolbar none="">
                                <button 
                                    className="button large right extra accent white"
                                    type="button"
                                    onClick={doConnect}
                                    //disabled={}
                                ><text><T>text.connect</T></text></button>
                            </toolbar>
                        </wrap>
                    </cont>
                </login>
            </view>
    } else {
        return <></>;
    }
};

export default connect(state => ({ isLoggedIn: state.isLoggedIn }), null)(Login);