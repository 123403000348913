import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Table, message } from 'antd';
import { T, DataTip } from '../../components/Translations';
import { Ajax } from '../../components/Ajax';
import { useCustomEvent, dispatchCustomEvent, useLoginCheck, useDelete } from '../../project/utilities';
import MainListWrapper from './MainListWrapper';
import Search from '../Filters/search';
import PropTypes from 'prop-types';
import { getColumns } from './helper';
import { connect } from 'react-redux';
import { openPopup, setListState } from '../../store/actions';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import EditableRow from './EditableRow';
import EditableCell from './EditableCell';
import { useHistory } from 'react-router-dom';
import DragableRow from './DragableRow';
import { validate } from '../Form/validation';
import FooterView from 'Shared/ListView/footer';

/**
 * the list view component, generates all the page structure with lists based on props
 * @param {obj} props contains : dataSource, columnConfig, search, filters, skipNewButton, newButtonTip, onClick, onDoubleClick, onContextMenu, onMouseEnter, onMouseLeave, onMoveRow, onCustomDelete, onLoaded, onNewClick, detailDefaultProps, apiUrl, inlineUpdateUrl, dataItemUrl, detailUrl, deleteUrl, deleteKeys, listUpdateEvent, isSimple, isChieldView, loading, staticFilter, rowClassName, rowSelection, openPopup, noPaging, noSort, skipEdit, showRefreshButton, expandable, editable, dragable, keepEditingStateOnload, nameField, tableLayout, listStates, listName, setListState, summary
 */
function ListView(props) { // NOSONAR
    const {
        className,
        columnConfig,
        dataSource,
        nameField,
        search,
        filters,
        staticFilter,
        noPaging,
        noSort,
        defaultSorting,
        onClick,
        onNewClick,
        onDoubleClick,
        onContextMenu,
        onMouseEnter,
        onMouseLeave,
        onMoveRow,
        onCustomDelete,
        onLoaded,
        detailDefaultProps,
        apiUrl,
        inlineUpdateUrl,
        dataItemUrl,
        detailUrl,
        detailFullUrl,
        getDetailUrl,
        deleteUrl,
        deleteKeys,
        deleteMessage,
        listUpdateEvent,
        loading,
        rowClassName,
        rowSelection,
        dragable,
        skipEdit,
        expandable,
        editable,
        validationFields,
        keepEditingStateOnload,
        listName,
        skipNewButton,
        newButtonTip,
        isSimple,
        isChieldView,
        small,
        scroll,
        hasTotalFooter,
        isGlobalLoading,
        tableLayout,
        auto_height,
        showRefreshButton,
        summary,
        setListState,
        listStates,
        openPopup,
        showHeader
    } = props;
    const history = useHistory();
    const listState = listName && listStates[listName];
    const [detailProps, setDetailProps] = useState({});
    const [pagination, setPagination] = useState({ current: 1, pageSize: 40 });
    const [sorts, setSorts] = useState(null);
    const [appliedFilter, setAppliedFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingController, setLoadingController] = useState(null);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [editingRecord, setEditingRecord] = useState(null);
    const [skipLoad, setSkipLoad] = useState(true);
    const [validation, setValidation] = useState({ isValid: true });

    const listRef = useRef(null);
    const viewRef = useRef(null);
    useLoginCheck(props);

    const onDelete = useDelete({ nameField, deleteUrl, deleteKeys, listUpdateEvent, deleteMessage });

    const loadEditingItem = useCallback((record) => {
        Ajax.get({
            url: dataItemUrl,
            data: { id: record.ID },
            success: function (response) {
                if (response && listRef.current) {
                    setEditingRecord({ ...response });
                }
            }
        })
    }, [dataItemUrl]);

    useEffect(() => {
        editingRecord && setValidation(validate({ data: editingRecord, validationFields }));
    }, [editingRecord, validationFields]);

    const onEdit = useCallback((record, rowNumber, event, isNewRecord) => {
        if (detailUrl || detailFullUrl || getDetailUrl) {
            history.push(detailFullUrl || (getDetailUrl && getDetailUrl(record, rowNumber, event, isNewRecord)) || (detailUrl + "/" + ((record && record.ID) || "create")));
        } else if (!editable || isNewRecord) {
            const { newTitle, editTitle, newData, popupType, popupClassName, fullScreen, windowKey } = detailProps;
            openPopup({
                windowKey,
                fullScreen: fullScreen,
                title: isNewRecord ? newTitle : editTitle,
                type: popupType,
                className: popupClassName,
                bodyProps: { ...(isNewRecord ? newData : { ID: record.ID, data: record }), onDelete, windowKey, detailProps }
            });
        } else {
            dataItemUrl ? loadEditingItem(record) : setEditingRecord({ ...record });
        }
    }, [detailProps, onDelete, openPopup, editable, loadEditingItem, dataItemUrl, detailUrl, history, detailFullUrl, getDetailUrl]);

    const onSave = useCallback(() => {
        validation.isValid && Ajax.post({
            url: inlineUpdateUrl,
            data: editingRecord,
            success: function (response) {
                if (response && listRef.current) {
                    if (response.HasError) {
                        message.error(response.Message);
                    }
                    else {
                        dispatchCustomEvent(listUpdateEvent, keepEditingStateOnload && { callback: () => { setEditingRecord(null) } });
                        message.success(response.Message);

                    }
                }
            }
        })
    }, [editingRecord, inlineUpdateUrl, keepEditingStateOnload, listUpdateEvent, validation])

    const loadData = useCallback((event) => {
        const detail = event && event.detail;
        if (dataSource) {
            setItems(dataSource);
            setTotal(dataSource.length);
        }
        else {
            if (!isLoading) {
                setIsLoading(true);
            }
            if (loadingController)
                loadingController.abort();
            let controller =
                Ajax.post({
                    url: apiUrl,
                    data: {
                        request: { Page: noPaging ? 0 : pagination.current, PageSize: noPaging ? 0 : pagination.pageSize, Sorts: sorts, Total: /* total uncomment if problem with latency calculating count  ||*/ -1 },
                        filterData: JSON.stringify({ ...appliedFilter, ...staticFilter })
                    },
                    success: function (response) {
                        if (response && listRef.current) {
                            let data = response.Data;
                            let dataLength = response.Data && response.Data.length;
                            let total = response.Total;
                            for (var i = 0; i < data.length; i++) { // NOSONAR
                                //data[i].date = new Date(data[i].date)
                                if (!data[i].key)
                                    data[i].key = data[i].ID;
                            };
                            if (onLoaded) {
                                let newData = onLoaded(data, sorts);
                                if (newData) {
                                    if (newData.length !== dataLength) {
                                        total += newData.length - dataLength;//if data length changes, change total to keep paging working
                                    }
                                    data = newData
                                };
                            }
                            if (!keepEditingStateOnload) { setEditingRecord(null) }
                            detail && detail.callback && detail.callback(response);
                            setItems(data);
                            setTotal(total);

                            if (pagination.current > 1 && Math.ceil(total / pagination.pageSize) < pagination.current) {
                                setPagination({ current: Math.floor(total / pagination.pageSize), pageSize: pagination.pageSize })
                            }

                            setIsLoading(false);
                            setLoadingController(null);
                        }
                    }
                });

            setLoadingController(controller);
        }
    }, [isLoading, appliedFilter, pagination, sorts, listRef, apiUrl, staticFilter, dataSource, noPaging, keepEditingStateOnload, onLoaded, loadingController])

    useEffect(() => {
        listRef.current = true;
        setDetailProps({ ...detailDefaultProps, ...props.detailProps })
        if (listState) {
            setSorts(listState.sorts);
            setPagination(listState.pagination);
            setAppliedFilter(listState.appliedFilter);
        } else {
            if (!noSort) {
                setSorts([defaultSorting || { Member: columnConfig[0].field, SortDirection: 0 }]);
            }
        }
        setSkipLoad(false);
        return () => {
            listRef.current = false;
        }
    }, [props.detailProps]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!skipLoad) {
            loadData();
        }
        listName && setListState({ listName, state: { sorts, pagination, appliedFilter } });
    }, [sorts, pagination, appliedFilter, dataSource, skipLoad]);// eslint-disable-line react-hooks/exhaustive-deps

    const setAppliedFilterExtend = useCallback((value) => {
        if (appliedFilter !== value) {
            setTotal(-1);
            setAppliedFilter(value);
        }
    }, [appliedFilter])



    useCustomEvent(listUpdateEvent, loadData);
    const columns = getColumns(columnConfig, !skipEdit && onEdit, onCustomDelete || (deleteUrl && onDelete), editingRecord, setEditingRecord, onSave, sorts, validation);

    const newChildButton = skipNewButton || (isChieldView &&
        <toolbar transparent="">
            <div className="button primary" onClick={(event) => {
                if (onNewClick) {
                    onNewClick()
                } else {
                    onEdit(null, null, event, true)
                }
            }}><icon>plus</icon><text>{newButtonTip || <T>text.new</T>}</text></div>
        </toolbar>);

    const newButton = skipNewButton ||
        <div className={'fab_container ' + (noPaging || total < pagination.pageSize ? 'bottom_s' : 'bottom') + ' right'}>
            <DataTip title={newButtonTip || 'text.new'}>
                <div effect='material' className='button fab primary' onClick={(event) => {
                    if (onNewClick) {
                        onNewClick()
                    } else {
                        onEdit(null, null, event, true)
                    }
                }}><icon>plus</icon></div>
            </DataTip>
        </div>;

    const components = (dragable && {
        body: { row: DragableRow }
    }) || (editable && {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    });

    const footer = () => (<FooterView
        columnConfig={columnConfig}
        items={items}
    />);

    const innerContent =
        <>
            {search && <Search {...search} setAppliedFilter={setAppliedFilterExtend} appliedFilter={appliedFilter} />}
            <view
                //{...(!isChieldView && !scroll ? { scroll: '' } : {})}
                //space=''
                light=''
                ref={viewRef}
                auto_height={auto_height && ''}
                small={small && ''}
            >

                {!isChieldView && newButton}
                <DndProvider backend={HTML5Backend}>
                    <Table
                        showHeader={showHeader}
                        loading={!isGlobalLoading && (isLoading || loading)}
                        components={components}
                        className={(className || '') + (newButton ? (noPaging || total < pagination.pageSize ? ' fab_grid' : ' pagination_grid') : '')} // NOSONAR
                        tableLayout={tableLayout}
                        showSorterTooltip={false}
                        rowSelection={rowSelection}
                        columns={columns}
                        footer={hasTotalFooter && footer}
                        summary={summary}
                        expandable={expandable}
                        rowClassName={rowClassName}
                        scroll={scroll}
                        dataSource={items}
                        {...(noPaging ? { pagination: { hideOnSinglePage: true, pageSize: Math.max(total, pagination.pageSize) } } : { pagination: { current: Math.min(Math.floor(total / pagination.pageSize) + 1, pagination.current), pageSizeOptions: ['5', '10', '20', '30', '40'], pageSize: pagination.pageSize, showSizeChanger: true, total: total || (items && items.length) || 0, hideOnSinglePage: true } })}
                        onChange={(pagination, filters, sorter, extra) => { // NOSONAR
                            noPaging || setPagination({ ...pagination });
                            noSort || setSorts((sorter.column && [{ Member: sorter.field, SortDirection: sorter.order === 'ascend' ? 0 : 1 }]) || null);
                        }}
                        sorts={sorts}
                        onRow={(record, rowIndex) => { // NOSONAR
                            if (dragable)
                                return {
                                    index: rowIndex, onMoveRow: (dragIndex, hoverIndex) => { onMoveRow && onMoveRow(dragIndex, hoverIndex); } // move row
                                };

                            return {
                                onClick: event => { onClick && onClick(record, rowIndex, event); }, // click row
                                onDoubleClick: event => { onDoubleClick ? onDoubleClick(record, rowIndex, event) : !skipEdit && !editable && onEdit(record, rowIndex, event); }, // double click row
                                onContextMenu: event => { onContextMenu && onContextMenu(record, rowIndex, event); }, // right button click row
                                onMouseEnter: event => { onMouseEnter && onMouseEnter(record, rowIndex, event); }, // mouse enter row
                                onMouseLeave: event => { onMouseLeave && onMouseLeave(record, rowIndex, event); }, // mouse leave row
                            };
                        }}
                    />
                </DndProvider>
                {newChildButton}
            </view>
        </>;

    return isChieldView ? innerContent
        : (isSimple ? // NOSONAR
            <>
                {innerContent}
            </>
            : <MainListWrapper
                filters={filters}
                appliedFilter={appliedFilter}
                setAppliedFilter={setAppliedFilterExtend}
                setPagination={setPagination}
                pagination={pagination}
                loadData={loadData}
                onEdit={onEdit}
                showRefreshButton={showRefreshButton}
            >
                {innerContent}
            </MainListWrapper>)
}


ListView.propTypes = {
    columns: PropTypes.array,
    filters: PropTypes.array,
    detailProps: PropTypes.object,
    detailDefaultProps: PropTypes.object,
    showRefreshButton: PropTypes.bool
};

ListView.defaultProps = {
    scroll: { y: 300 },
    filters: null,
    columns: [],
    validationFields: [],
    detailProps: {},
    showRefreshButton: false,
    detailDefaultProps: {
        fullScreen: true,
        newTitle: 'text.new',
        newData: { ID: "", data: { nom: '' } },
        editTitle: 'text.edit'
    }
};

const isGlobalLoaders = state => {
    return state.loaders.length > 0;
};

export default connect(state => ({ listStates: state.listStates, isGlobalLoading: isGlobalLoaders(state) }),
    dispatch => ({
        openPopup: (data) => dispatch(openPopup(data)),
        setListState: (data) => dispatch(setListState(data))
    }))(ListView);
