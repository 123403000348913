import React, { useCallback } from 'react';
import { ValidationInput } from '~/Shared/Form/validation';
import useDetail from '~/Shared/useDetail';
import { T } from '../../../components/Translations';
import { ApiUrl } from '../../../project/Defines.js';
import { dispatchCustomEvent, EVENT_NAME } from '../../../project/utilities';
import { FormField } from '../../../Shared/Form/formFields';


export default function SalesNetworkPicker(props) {
    const { windowKey, data } = props;

    const listUpdateEvent = EVENT_NAME.SALES_NETWORK_PICKER_LIST_UPDATE;
    const {
        dataItem,
        setDataItem,
        updateData,
        onChangeData,
        closePopup,
        detailRef,
        isNew,
        validation } = useDetail({
            data,
            updateUrl: ApiUrl.SalesNetworkNatureCodeUpdate,
            windowKey,
            validationFields: ['nature_code', 'code_client']
        });

    const updateSuccessCallback = useCallback((response) => {
        //debugger
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, listUpdateEvent, detailRef, closePopup]);




    return (<>
        {dataItem &&
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections'>
                            <FormField label="text.nature_code">
                                <ValidationInput
                                    name='nature_code'
                                    type='text'
                                    inputType='number'
                                    validation={validation}
                                    value={dataItem.nature_code}
                                    //onChange={onChangeData}
                                    min={1} max={100000} placeholder=''
                                    onChange={(e) => { onChangeData({ target: { name: 'nature_code', value: e } }) }} // NOSONAR
                                />
                            </FormField>
                            <FormField label="text.code_client">
                                <ValidationInput
                                    name='code_client'
                                    type='text'
                                    validation={validation}
                                    value={dataItem.code_client}
                                    onChange={onChangeData}
                                />
                            </FormField>
                        </div>
                    </div>
                </div>
                <toolbar>
                    <wrap>
                        <action right=''>
                            <div
                                onClick={() => { updateData(updateSuccessCallback) }}
                                className='button'
                            ><icon>save</icon>{isNew ? <T>text.add</T> : <T>text.save</T>}
                            </div>
                        </action>
                    </wrap>
                </toolbar>
            </form>
        }
    </>);
}
