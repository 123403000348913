import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message, Spin } from 'antd';
import { AppPages, ApiUrl } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { Ajax } from '../components/Ajax.js';
import { connect } from 'react-redux';
import { T } from '../components/Translations.js';

//const loaderName = 'WARRANTY_REQUEST'  // NOSONAR

/**
 * the WarrantyRequestActions Component for external action
 * @exports WarrantyRequestActionsequest
 */
function WarrantyRequestActions(props) { // NOSONAR
  const { history } = props;
  const { action, id, hash } = useParams();
  const [ errorMessage, setErrorMessage ] = useState();
  const ref = useRef(false);

    useEffect(() => {
        ref.current = true;
        doAction();
        console.log(action);
        console.log(id);
        console.log(hash);
        return () => {
            ref.current = false;
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const doAction = useCallback(() => { //NOSONAR
      if (action && id && hash){
        //showLoader(loaderName) // NOSONAR
        Ajax.get({
            url: `${ApiUrl.WarrantyRequestActions}/${action}/${id}/${hash}`,
            headers: {
                Authorization: "Bearer " + props.appToken
            },
            success: function (response) {
                //hideLoader(loaderName) // NOSONAR
                if (response && ((response.status && response.status === 200) || response.redirectUrl)) {
                    window.location.href = response.redirectUrl;
                } else {
                    let msgText = <T>message.error_api</T>;
                    if (response) {
                        msgText = response.message || <T>message.error_api</T>;
                    }
                    setErrorMessage(msgText)
                    message.error(msgText);

                }
            },
            error: function (response) {
                //hideLoader(loaderName) // NOSONAR
                let msgText = <T>message.error_api</T>;
                if (response) {
                    msgText = response.message || <T>message.error_api</T>;
                }
                setErrorMessage(msgText)
                message.error(msgText);
            }
        })
      } else {
        history.push(Project.getPageUrl(AppPages.Login));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, history, action, id, hash]);




    return errorMessage ? <>{errorMessage}</> : <><div preloader="" style={{ "margin": "25% auto" }}><Spin size="large" /></div></>;
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, culture: state.culture, appToken: state.appToken }), null)(WarrantyRequestActions);