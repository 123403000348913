import React, { useCallback } from 'react';

import { ApiUrl } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';

import { T } from 'components/Translations';
import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { ValidationInput } from '../../../Shared/Form/validation';

/**
 * the Technisian details of Technisian tab of User(Societe)
 * @exports User-Technisian
 */
export default function SocieteTechnisianDetails(props) {
    const { windowKey, data, onDelete } = props;
    const listUpdateEvent = EVENT_NAME.SOCIETE_TECHNISIAN_LIST_UPDATE;

    const { dataItem, setDataItem, updateData, onChangeData, closePopup, onDetailDelete, detailRef, validation } = useDetail({
        data: data,
        updateUrl: ApiUrl.SocieteTechnisianUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.SocieteTechnisianLoad,
        nameField: 'nom',
        deleteUrl: ApiUrl.SocieteTechnisianDelete,
        listUpdateEvent,
        onLoadData: (response) => {
            response.societe_uuid = data.societe_uuid;
            setDataItem(response);
            return true;
        },
        validationFields: ['prenom', 'nom']
    });

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }
        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
    }, [setDataItem, detailRef, listUpdateEvent]);

    return (<>
        {dataItem &&
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.first_name">
                                <ValidationInput name='prenom' type='text' value={dataItem.prenom} maxLength="45" onChange={onChangeData} validation={validation} />
                            </FormField>
                            <FormField label="text.last_name">
                                <ValidationInput name='nom' type='text' value={dataItem.nom} maxLength="45" onChange={onChangeData} validation={validation} />
                            </FormField>
                        </div>
                    </div>
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                                {dataItem.ID &&
                                    <div className='button' onClick={(e) => {
                                        (onDelete && onDelete(dataItem, closePopup)) ||
                                            onDetailDelete(dataItem, closePopup);
                                    }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>
                </div>
            </form>}
    </>);
}