import React, { useCallback, useEffect, useRef } from 'react';
import { message, Spin } from 'antd';
import { AppPages, ApiUrl } from '../project/Defines.js';
import { Project } from '../project/Project.js';
import { Ajax } from '../components/Ajax';
import { connect } from 'react-redux';
import { login, logout, clearListStates } from '../store/actions/index.js';
//import { showLoader, hideLoader } from '../Shared/Loader'  // NOSONAR
import { T } from '../components/Translations.js';

//const loaderName = 'LOGIN_CALLBACK'  // NOSONAR

function getParams() {
  return new URLSearchParams(window.location.search);
}

/**
 * the LoginCallback Component where user authorization occurs
 * @exports LoginCallback
 */
function LoginCallback(props) {
  const { history } = props;
  const query = getParams();
  const code = query.get("code") || "";
  const state = query.get("state") || "";
  const session_state = query.get("session_state") || "";
  const ref = useRef(false);

    useEffect(() => {
        ref.current = true;
        doLogin();
        return () => {
            ref.current = false;
        }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const doLogin = useCallback(() => { //NOSONAR
      if (code){
        //showLoader(loaderName) // NOSONAR
        Ajax.post({
            url: ApiUrl.LoginByCode,
            headers: {
                Authorization: "Bearer " + props.appToken
            },
            data: {
                code: code,
                state: state,
                session_state: session_state,
                callback: `${window.location.origin}/wso/callback`
            },
            success: function (response) {
                //hideLoader(loaderName) // NOSONAR
                if (response && ((response.status && response.status === 200) || response.userToken)) {
                    const { redirectUrl } = response;
                    props.dispatch(clearListStates());
                    props.dispatch(login({ userToken: response.userToken, userName: response.userName, culture: response.culture, role: response.role, settings: response.settings || [] }));
                    Project.setLocalStorage("userToken", response.credentials, response.credentials.expires_in - 600);
                    //Project.setLocalStorage("userToken", response.credentials, 60) // NOSONAR
                    if (redirectUrl && redirectUrl !== Project.getPageUrl(AppPages.Login)) {
                      history.push(redirectUrl);
                    }
                    else {
                      history.push(Project.getPageUrl(AppPages.Home));
                    }
                } else {
                    if (response) {
                        message.error(response.title || <T>message.error_api</T>)
                    } else {
                        message.error(<T>message.error_api</T>)
                    }

                }
            },
            error: function (response) {
                //hideLoader(loaderName) // NOSONAR
                if (response) {
                    message.error(response.message || <T>message.error_api</T>)
                } else {
                    message.error(<T>message.error_api</T>)
                }

                history.push("/");

            }
        })
      } else {
        props.dispatch(logout());
        history.push(Project.getPageUrl(AppPages.Login));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, history, code]);




    return <><div preloader="" style={{ "margin": "25% auto" }}><Spin size="large" /></div></>;
}

export default connect(state => ({ isLoggedIn: state.isLoggedIn, networks: state.networks, network: state.network, culture: state.culture, appToken: state.appToken }), null)(LoginCallback);