import React, { useCallback, useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { message, Dropdown, Tooltip, notification } from 'antd';

import { ApiUrl, AppPages } from 'project/Defines.js';
import { EVENT_NAME, dispatchCustomEvent } from 'project/utilities';
import { T } from 'components/Translations';
import useDetail from 'Shared/useDetail';
import { FormField } from 'Shared/Form/formFields.js';
import { Ajax } from '../../../components/Ajax';
import { ValidationDropDownValueList, ValidationInput } from '../../../Shared/Form/validation';
import DropDownValueList from 'Shared/DropDown';
import { getLanguages } from '../../../Shared/Translation';
import { showLoader, hideLoader } from 'Shared/Loader';
import { openPopup, changeUserToken } from '../../../store/actions';
import DetailView from '../../../Shared/DetailView';
import { Project } from '../../../project/Project';

const loaderName = 'SOCIETE_USER_LOADER';

const cultures = getLanguages();
const defaultValidationFields = [
    { name: 'email', rules: [{ type: 'email' }, { type: 'required' }] }, 'sso_cible', 'telephone' //, 'sso_title'
];
const defaultValidationColaborator = [
    'mailBrand',
    { name: 'login', rules: [{ type: 'email' }, { type: 'required' }] }, 'sso_cible', 'telephone'
];
const defaultValidationGestionare = [
    'mailBrand',
    { name: 'email', rules: [{ type: 'email' }, { type: 'required' }] }, 'sso_cible', 'telephone'
];

const checkRestriction = (acces_uuids, value) => {
    if (acces_uuids) {
        if (acces_uuids.length && acces_uuids.includes(value)) {
            return false;
        }
    }
    return true;
}

const IsSSOUser = (dataItem) => {
    return dataItem && dataItem.IsSSOUser && dataItem.IsSSOUser.indexOf(dataItem.sso_cible) !== -1;
}
const GetSSOError = (dataItem) => {
    return dataItem && dataItem.SSOErrors ? dataItem.SSOErrors[dataItem.sso_cible] : "";
}

const ssoGetLoginType = (dataItem) => {
    if (dataItem) {
        return dataItem.sso_cible;
    }
    return 'group';
}


/**
 * the User details of User tab of User(Societe)
 * @exports User-UserDetails
 */
function SocieteUserDetails(props) { // NOSONAR
    const { id, societe_uuid } = useParams();
    const dispatch = useDispatch();

    const { windowKey, data, onDelete, detailProps } = props;
    const [accesUtilList, setAccessUtilList] = useState([]);
    const [accesAccessList, setAccesAccesList] = useState([]);
    const [ssocible, setSsocible] = useState([]);
    const [activeSsocible, setActiveSsocible] = useState([]);
    const [universes, setUniverses] = useState([]);
    const [validationFields, setValidationFields] = useState(defaultValidationFields);
    const [disableRole, setDisableRole] = useState(false);
    const [societe, setSociete] = useState({});
    const [isGestionnaire, setIsGestionnaire] = useState(false);

    const listUpdateEvent = EVENT_NAME.SOCIETE_USER_LIST_UPDATE;

    const { isNew, dataItem, setDataItem, updateData, onChangeData, closePopup, onDetailDelete, detailRef, validation } = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.SocieteUserUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.SocieteUserLoad,
        nameField: 'nom',
        deleteUrl: ApiUrl.SocieteUsersDelete,
        backPage: AppPages.UserDetails,
        backPageRoute: societe_uuid,
        backPageParams: {"tab": 1},
        listUpdateEvent,
        validationFields,
        changingValidation: true,
        detailProps,
        detailUrl: Project.getPageUrl(AppPages.SocieteUserDetails, societe_uuid),
        onLoadData: (response) => {
            setDataItem(response);
            return true;
        }
    });

    useEffect(() => {
        Ajax.post({
            url: ApiUrl.ValueList,
            data: {
                valueList: 'acces_societe_user_utils',
            },
            success: function (response) {
                if (response && detailRef.current) {
                    const data = response;
                    setAccessUtilList(data);
                }
            }
        });
        Ajax.post({
            url: ApiUrl.ValueList,
            data: {
                valueList: 'acces_societe_user_acces',
            },
            success: function (response) {
                if (response && detailRef.current) {
                    const data = response;
                    setAccesAccesList(data);
                }
            }
        });
        Ajax.post({
            url: ApiUrl.ValueList,
            data: {
                valueList: 'univers_connect_as',
                additionalData: societe_uuid
            },
            success: function (response) {
                if (response && detailRef.current) {
                    const data = response;
                    setUniverses(data);
                }
            }
        });

        Ajax.post({
            url: ApiUrl.ValueList,
            data: {
                valueList: 'sso_cible',
            },
            success: function (response) {
                if (response && detailRef.current) {
                    const data = response;
                    setSsocible(data);

                    let _activeSsocible = data.filter(sso => { return sso.active === true;  });
                    setActiveSsocible(_activeSsocible);
                }
            }
        });

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (societe_uuid) { //dataItem && dataItem.societe_uuid) {
            Ajax.get({
                url: ApiUrl.UserLoad,
                data: {
                    ID: societe_uuid //dataItem.societe_uuid,
                },
                success: function (response) {
                    if (response && detailRef.current) {
                        setSociete(response);
                        //setDisableRole(response.has_gestionnaire) // NOSONAR
                        setDisableRole(true);
                    }
                }
            });
        }
    }, [/*dataItem && dataItem.societe_uuid,*/ societe_uuid, detailRef]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDataItem({ ...dataItem, mailBrand: null })
    }, [dataItem && dataItem.sso_cible]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isNew) {
            if (isGestionnaire) {
                setValidationFields(disableRole ? [...defaultValidationGestionare, "pays"] : [...defaultValidationGestionare, "roles", "pays"]);
            } else {
                setValidationFields(disableRole ? [...defaultValidationColaborator, "pays"] : [...defaultValidationColaborator, "roles", "pays"]);
            }
        } else if (dataItem /*&& !dataItem.IsSSOUser*/) {
            setValidationFields([...defaultValidationFields, "mailBrand"]);
        }
    }, [dataItem, dataItem && dataItem.roles, isNew, isGestionnaire, disableRole]); // eslint-disable-line react-hooks/exhaustive-deps


    const connectAsUser = useCallback((universe_uuid, domain, login_type, host_name) => {
        showLoader(loaderName);

        Project.refreshUserToken((token) => {
            dispatch(changeUserToken(token.access_token));

            Ajax.post({
                url: ApiUrl.ConnectAsUser,
                data: {
                    user_uuid: dataItem.ID,
                    universe_uuid: universe_uuid === 'station-web' ? null : universe_uuid,
                    domain,
                    login_type,
                    portal: universe_uuid === 'station-web' ? 'web_station' : null,
                    host_name,
                    user_token: token.access_token,
                    back_url: window.location.href
                },
                success: function (response) {
                    if (!response.hasError) {
                        setTimeout(() => {
                            window.location.href = response;
                        }, 100);
                    } else {
                        message.error(response.message);
                        hideLoader(loaderName);
                    }
                },
                error: function (error) {
                    hideLoader(loaderName);
                }
            });
        })
        
    }, [dataItem, dispatch]);

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {

            if (response.SsoMessage) {
                notification.info({
                    message: `SSO Notification`,
                    description: response.SsoMessage,
                    placement: 'topRight',
                });
            }
            setDataItem({ ...response, mailBrand: dataItem.mailBrand, sso_cible: dataItem.sso_cible, sso_title: dataItem.sso_title });
        }
        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
    }, [dataItem, setDataItem, detailRef, listUpdateEvent]);

    const updateDataInternal = useCallback((sendMail) => {
        updateData(updateSuccessCallback, null, { additionalData: { sendMail, societe_uuid } });
    }, [updateSuccessCallback, updateData, societe_uuid]);

    const checkEmail = useCallback(() => {
        Ajax.post({
            url: ApiUrl.SocieteUserCheckEmail,
            data: {
                email: dataItem.email
            },
            success: function (response) {
                if (response && detailRef.current) {
                    if (response.HasError) {
                        message.error(response.Message);
                    } else {
                        message.success("Ok");
                    }
                }
            }
        });
    }, [dataItem, detailRef]);


    const onAccessChange = useCallback((event) => { // NOSONAR
        let newData = { ...dataItem };
        let acces_uuids = dataItem.acces_uuids || [];
        let item = accesAccessList.find((i) => i.value === event.target.name);
        if (!event.target.checked) {
            if (!acces_uuids.includes(event.target.name)) {
                acces_uuids.push(event.target.name);
            }

            if (item) {
                if (item.slug === "voir-les-prix-remisés") {
                    item = accesAccessList.find((i) => i.slug === "commande");
                    if (item) {
                        if (!acces_uuids.includes(item.value)) {
                            acces_uuids.push(item.value);
                        }
                    }
                }
            }
        } else {
            let index = acces_uuids.indexOf(event.target.name);
            if (index !== -1) {
                acces_uuids.splice(index, 1);
            }

            if (item) {
                if (item.slug === "commande") {
                    item = accesAccessList.find((i) => i.slug === "voir-les-prix-remisés");
                    if (item) {
                        index = acces_uuids.indexOf(item.value);
                        if (index !== -1) {
                            acces_uuids.splice(index, 1);
                        }
                    }
                }
            }
        }
        setDataItem({ ...newData, acces_uuids });
    }, [setDataItem, dataItem, accesAccessList]);

    const onLivraisonItemSocieteChange = useCallback((event) => {
        let newData = { ...dataItem };
        if (event.target.checked) {
            newData.livraison_idem_societe = true;
            newData.adresse = societe.livraison_adresse;
            newData.adresse_complement = societe.livraison_adresse_complement;
            newData.code_postal = societe.livraison_code_postal;
            newData.ville = societe.livraison_ville;
            newData.pays = societe.livraison_pays;
        } else {
            newData.livraison_idem_societe = false;
        }
        setDataItem(newData);
    }, [setDataItem, dataItem, societe]);

    const onChangeLoginData = useCallback((e) => {
        let newData = { ...dataItem };
        newData.login = e.target.value;
        if (!isGestionnaire && isNew) {
            newData.email = e.target.value;
        }
        setDataItem(newData);
    }, [isGestionnaire, isNew, dataItem, setDataItem]);

    const onChangeRoleData = useCallback((e, data) => {
        let newData = { ...dataItem };
        newData.roles = e;
        if (isNew) {
            let gestionnaire_uuid = data.filter((item) => item.text === "Gestionnaire")[0].value;
            setIsGestionnaire(e === gestionnaire_uuid);

            if (e === gestionnaire_uuid) {
                newData.login = societe.siret;
            } else {
                newData.login = newData.email;
            }
        }
        setDataItem(newData);
    }, [isNew, dataItem, societe, setDataItem, setIsGestionnaire]);

    const checkButton = <>
        <div className="button micro" onClick={checkEmail}><icon>done</icon></div>
    </>;

    let emailBrands = dataItem && dataItem.sso_cible ? JSON.parse(activeSsocible.filter(sso => sso.value === dataItem.sso_cible)[0].brands).map(sso => { return { value: sso, text: sso }; }) : [];
    console.log(emailBrands);
    let isGroupSelected = dataItem && dataItem.sso_cible === "group";
    let availableAccessList = isGestionnaire || (dataItem && (dataItem.role_slugs || '').includes('gestionnaire')) ? accesAccessList.filter(t => !t.collaborator_access) : accesAccessList;
    const isReadOnly = props.settings && !props.settings?.ID360IsDisabled;

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.UserDetails, societe_uuid, { tab: 1 })}
            title={isNew ? <T>text.new_user</T> : dataItem.login}
        >
            <form>
                <div className='form_container'>
                    <div className='section_group'>
                        <div className='sections ' style={isReadOnly ? { display: "none"} : {}}>
                            <FormField label="text.roles">
                                <ValidationDropDownValueList
                                    validation={validation}
                                    name='roles'
                                    value={(isNew && disableRole) ? 'Collaborateur' : dataItem.roles}
                                    valuelist='user_roles'
                                    onChange={onChangeRoleData}
                                    disabled={!isNew || disableRole}
                                />
                            </FormField>
                        </div>
                        <div className='sections '>
                            <FormField label="text.identifiant">
                                <div className="input_cont">
                                    <ValidationInput
                                        validation={validation}
                                        name='login'
                                        type='text'
                                        value={dataItem.login || ''}
                                        onChange={onChangeLoginData}
                                        disabled={isGestionnaire ? true : !isNew}
                                    />
                                    {isNew && dataItem.login && checkButton}
                                    <i></i>
                                </div>
                            </FormField>
                        </div>
                        <div className='sections '>
                            <FormField label="text.culture">
                                <DropDownValueList name='culture' value={dataItem.culture} items={cultures} onChange={data => onChangeData({ target: { name: 'culture', value: data } })} // NOSONAR
                                />
                            </FormField>
                        </div>

                    </div>

                    {
                        //enable_new_sso === true &&
                        activeSsocible && activeSsocible.length > 0 &&
                        <>
                            <div className='section_group'>
                                <div className='sections '>
                                    <div className="form_fields"><div className="section_title"><text><T>text.sso</T></text></div><div className="form_separator"></div></div>
                                </div>
                            </div>
                                    {
                                        !isNew && ssocible && ssocible.length > 0 &&
                                        ssocible.map(sso => {
                                            let loginType_info = ssoGetLoginType({ sso_cible: sso.value });
                                            let isSSOUser_info = IsSSOUser({ sso_cible: sso.value, IsSSOUser: dataItem.IsSSOUser });
                                            let ssoError_info = GetSSOError({ sso_cible: sso.value, SSOErrors: dataItem.SSOErrors });
                                            let universeList_info = 
                                                <div className="list_menu ant-dropdown-menu">
                                                    <ul style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                                                        {universes
                                                            .filter(u => {
                                                                return JSON.parse(u.sso_codes).indexOf(loginType_info) !== -1;
                                                            })
                                                            .map((u, i) => <Fragment key={"connect_" + u.value}>
                                                                <li command="user" onClick={() => connectAsUser(u.value, u.domain, loginType_info, u.host)}><a title={u.host}><icon></icon><text>{u.text}</text></a></li> { /*eslint-disable-line jsx-a11y/anchor-is-valid*/}
                                                                {i < universes.filter(u => {
                                                                    return JSON.parse(u.sso_codes).indexOf(loginType_info) !== -1;
                                                                }).length - 1 && <li className="separator"></li>}
                                                            </Fragment>)}

                                                    </ul>
                                                </div>;
                                            return <div className='section_group' key={sso.value}>
                                                    <div className='sections'>
                                                        <FormField>
                                                        <div className='data_label'>{sso.text}</div>
                                                        </FormField>
                                                    </div>
                                                    <div className='sections '>
                                                        <FormField>
                                                        <label checkbox=''><input name='IsSSOUser_info' type='checkbox' checked={isSSOUser_info || false} readOnly /><box><check></check></box><text><T>text.existe_sso</T></text></label>
                                                        {
                                                            ssoError_info &&
                                                            <Tooltip placement="top" title={ssoError_info}>
                                                                <action><icon style={{ color: "red" }}>info</icon></action>
                                                            </Tooltip>
                                                        }
                                                        </FormField>
                                                    </div>
                                                    {
                                                        universes
                                                        .filter(u => {
                                                            return JSON.parse(u.sso_codes).indexOf(loginType_info) !== -1;
                                                        }).length > 0 && isSSOUser_info &&
                                                        <div className='sections '>
                                                            <FormField>
                                                                <Dropdown
                                                                    className=""
                                                                    trigger={['click']}
                                                                    placement="topRight"
                                                                    overlay={universeList_info}>
                                                                    <div effect="material" className="button special" data-tip=""> <icon>user</icon><T>text.connect_as</T></div>
                                                                </Dropdown>
                                                            </FormField>
                                                        </div>
                                                    }
                                                </div>
                                            ;
                                        })
                                    }
                            <div className='section_group'>

                                <div className='sections '>
                                    <FormField label="text.sso_cible">
                                        <ValidationDropDownValueList
                                            validation={validation}
                                            disableCach
                                            name='sso_cible'
                                            items={activeSsocible}
                                            type='text'
                                            value={dataItem.sso_cible || ''}
                                            //valuelist='sso_cible'
                                            //disabled={!enable_new_sso}
                                            onChange={e => onChangeData({ target: { name: 'sso_cible', value: e } })} // NOSONAR
                                            addEmptyValue
                                        />
                                    </FormField>
                                </div>
                                {dataItem.sso_cible &&
                                    <>
                                        <div className='sections '>
                                            <FormField label="text.mail_brand">
                                                <ValidationDropDownValueList
                                                autoSelectFirst={!isGroupSelected}
                                                validation={validation}
                                                name='mailBrand'
                                                type='text'
                                                defaultValue={dataItem.mailBrand || ''}
                                                value={dataItem.mailBrand || ''}
                                                //valuelist='email_brands'
                                                valuelist={emailBrands}
                                                parentValue={dataItem.sso_cible}
                                                disableCach
                                                onChange={e => onChangeData({ target: { name: 'mailBrand', value: e } })} // NOSONAR
                                                addEmptyValue={isGroupSelected}
                                                disabled={!isGroupSelected}
                                                />
                                            </FormField>
                                        </div>
                                        {/* <div className='sections '>
                                            <FormField label="text.title">
                                                <ValidationDropDownValueList
                                                    validation={validation}
                                                    disableCach
                                                    name='sso_title'
                                                    items={[
                                                        { 'value': 1, 'text': 'Madame'},
                                                        { 'value': 2, 'text': 'Monsieur'},
                                                    ]}
                                                    type='text'
                                                    value={dataItem.sso_title || ''}
                                                    //valuelist='sso_cible'
                                                    //disabled={!enable_new_sso}
                                                    onChange={e => onChangeData({ target: { name: 'sso_title', value: e } })} // NOSONAR
                                                    //addEmptyValue
                                                />
                                            </FormField>
                                        </div> */}
                                    </>
                                }
                            </div>
                        </>
                    }

                    <div className='section_group '>
                        <div className='sections wide'>
                            <div className="form_fields">
                                <label checkbox=''><input name='livraison_idem_societe' type='checkbox' defaultChecked={dataItem.livraison_idem_societe} onChange={onLivraisonItemSocieteChange} /><box><check></check></box><text><T>text.copy_from_societe</T></text></label><div className="form_separator"></div>
                            </div>
                        </div>
                    </div>
                    <div className='section_group'>
                        <div className='sections '>
                            <FormField label="text.name">
                                <input name='nom' type='text' value={dataItem.nom || ''} maxLength="40" onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.prenom">
                                <input name='prenom' type='text' value={dataItem.prenom || ''} maxLength="40" onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.email">
                                <div className="input_cont">
                                    <ValidationInput
                                        validation={validation}
                                        name='email'
                                        type='text'
                                        value={dataItem.email || ''}
                                        onChange={onChangeData}
                                        disabled={!isGestionnaire && isNew}
                                    />
                                    <i></i>
                                </div>
                            </FormField>
                            <FormField label="text.telephone">
                                <ValidationInput validation={validation} name='telephone' type='text' value={dataItem.telephone || ''} onChange={onChangeData} />
                            </FormField>
                            <FormField label="text.fax">
                                <input name='fax' type='text' value={dataItem.fax || ''} onChange={onChangeData} />
                            </FormField>
                        </div>
                        <div className='sections '>
                            <FormField label="text.address">
                                <input name='adresse' type='text' value={dataItem.adresse || ''} onChange={onChangeData} disabled={dataItem.livraison_idem_societe} />
                            </FormField>
                            <FormField label="text.address_complement">
                                <input name='adresse_complement' type='text' value={dataItem.adresse_complement || ''} onChange={onChangeData} disabled={dataItem.livraison_idem_societe} />
                            </FormField>
                            <FormField label="text.code_postal">
                                <input name='code_postal' type='text' value={dataItem.code_postal || ''} onChange={onChangeData} disabled={dataItem.livraison_idem_societe} />
                            </FormField>
                            <FormField label="text.city">
                                <input name='ville' type='text' value={dataItem.ville || ''} onChange={onChangeData} disabled={dataItem.livraison_idem_societe} />
                            </FormField>
                            <FormField label="text.country">
                                <ValidationDropDownValueList
                                    validation={validation}
                                    name='pays' value={dataItem.pays || ''}
                                    valuelist='pays' onChange={e => onChangeData({ target: { name: 'pays', value: e } })} // NOSONAR
                                    disabled={dataItem.livraison_idem_societe /*|| dataItem.IsSSOUser*/}
                                    showSearch
                                    searchOptions={{
                                        noAccent: true,
                                        contains: true
                                    }} />
                            </FormField>
                        </div>
                    </div>
                    <div className='section_group'>
                        <div className='sections '>
                            <div className="form_fields"><div className="section_title"><text><T>text.access</T></text></div><div className="form_separator"></div></div>
                        </div>
                    </div>
                    <div className='section_group'>
                        {[1, 2, 3, 4].map((majorIndex) => {
                            let culumnCount = Math.min(accesUtilList.length, 4);
                            return <div className='sections' key={'checkSection' + majorIndex}>
                                {accesUtilList.filter((acc, i) => {
                                    return (i + 1) % culumnCount === majorIndex % culumnCount;
                                })
                                    .map(ac =>
                                        <FormField key={ac.value}>
                                            <label checkbox=''><input name={ac.value} type='checkbox' checked={checkRestriction(dataItem.acces_uuids, ac.value)} onChange={onAccessChange} /><box><check></check></box><text>{ac.text}</text></label>
                                        </FormField>
                                    )}
                            </div>
                        }
                        )}
                    </div>
                    <div className='section_group'>
                        <div className='sections '>
                            <div className="form_fields"><div className="section_title"><text><T>text.utilities</T></text></div><div className="form_separator"></div></div>
                        </div>
                    </div>
                    <div className='section_group'>
                        {[1, 2, 3, 4].map((majorIndex) => {
                            let culumnCount = Math.min(availableAccessList.length, 4);
                            return <div className='sections' key={'checkSection' + majorIndex}>
                                {availableAccessList.filter((acc, i) => (i + 1) % culumnCount === majorIndex % culumnCount)
                                    .map(ac =>
                                        <FormField key={ac.value}>
                                            <label checkbox=''><input name={ac.value} type='checkbox' checked={checkRestriction(dataItem.acces_uuids, ac.value)} onChange={onAccessChange} /><box><check></check></box><text>{ac.text}</text></label>
                                        </FormField>
                                    )}
                            </div>
                        }
                        )}
                    </div>
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div
                                    onClick={(e) => { updateDataInternal(false); }}
                                    className='button'>
                                    <icon>save</icon>{isNew ? <T>text.creation</T> : <T>text.save</T>}
                                </div>
                                {isGestionnaire && isNew &&
                                    <div
                                        onClick={(e) => { updateDataInternal(true); }}
                                        className='button'>
                                        <icon>save</icon><T>text.creation_and_inform_user</T>
                                    </div>
                                }
                                {dataItem.ID &&
                                    <div className='button' onClick={(e) => {
                                        onDetailDelete(dataItem) || (onDelete && onDelete(dataItem, closePopup))
                                    }}>
                                        <icon>delete</icon><T>text.delete</T>
                                    </div>}
                            </action>
                        </wrap>
                    </toolbar>
                </div>
            </form>
        </DetailView>}
    </>);
}


export default connect(state => ({ listStates: state.listStates, settings: state.settings, userToken: state.userToken }),
    dispatch => ({
        openPopup: (options) => dispatch(openPopup(options))
    }))(SocieteUserDetails);