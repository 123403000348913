
import { Project } from '../../project/Project';
const { round2Decimals } = Project;

export const getQuantite = (article) => {
    return article.quantite;
}

export const getUnitPrixHT = (article) => {
    return article.prix_ht;
}

export const getPrixHT = (article) => {
    return getUnitPrixHT(article) * getQuantite(article);
}

export const getUnitDeeeHT = (article) => {
    return article.deee_ht;
}

export const getDeeeHT = (article) => {
    return round2Decimals(getUnitDeeeHT(article) * getQuantite(article));
}

export const getEcoContr = (article) => {
    let ecoContr = article.poids * article.pu_pmcb * getQuantite(article);

    return ecoContr ? round2Decimals(Math.max(ecoContr, 0.01)) : 0;
}

//export const getTotalDeee = (article) => { // NOSONAR
//    return round2Decimals(getDeeeHT(article) * (1 + getTVACoefficent(article))) // NOSONAR
//} // NOSONAR

export const getTotalHT = (article) => {
    return getPrixHT(article) + getDeeeHT(article) + getEcoContr(article);
}

export const getTVACoefficent = (article) => {
    return article.tva / 100;
}

export const getTVA = (article) => {
    return getTotalHT(article) * getTVACoefficent(article);
}

//export const getTotalTTC = (article, dataItem) => { // NOSONAR
//    return round2Decimals(getTotalHT(article) + dataItem.fraisdeport + getTVA(article)) // NOSONAR
//} // NOSONAR

export const getTVAAmount = (dataItem) => { // NOSONAR
    if (!(dataItem.lignes && dataItem.lignes.length)) {
        return '-';
    }

    return round2Decimals(dataItem.lignes.reduce((sum, article) => {
        return sum + getTVA(article);
    }, 0) + (dataItem.fraisdeport * getTVACoefficent(dataItem.lignes[0])))
}

export const getTotalHTAmount = (dataItem) => {
    return dataItem.lignes.reduce((sum, article) => { return sum + getTotalHT(article); }, 0)
}

//export const getTotalDeeeAmount = (dataItem) => { // NOSONAR
//    return dataItem.lignes.reduce((sum, article) => { return sum + (getTotalDeee(article)); }, 0) // NOSONAR
//} // NOSONAR

export const getTotal = (dataItem) => {
    return getTotalHTAmount(dataItem) + dataItem.fraisdeport + getTVAAmount(dataItem);
}

export const getEcoPartAmount = (dataItem) => {
    return dataItem.lignes.reduce((sum, article) => { return sum + (getDeeeHT(article)); }, 0)
}

export const getEcoContrAmount = (dataItem) => {
    return dataItem.lignes.reduce((sum, article) => { return sum + (getEcoContr(article)); }, 0)
}