import React, { useState, useCallback, useRef, useEffect } from 'react';
import { message } from 'antd';

import { Ajax } from 'components/Ajax';
import { ApiUrl } from 'project/Defines.js';
import ListView from 'Shared/ListView';
import { COLUMN_TYPE } from 'Shared/ListView/helper';
import { T } from 'components/Translations';

/**
 * the Normes list of Normes's Infos tab
 * @exports Universe-Family-Normes
 */
export default function Normes(props) {
    const { uuid } = props;
    const [selectedRows, setSelectedRows] = useState([]);
    const [disabledRows, setDisabledRows] = useState([]);
    const viewRef = useRef(null);
    const columnConfig = [
        {
            text: 'text.name',
            field: 'libelle',
            type: COLUMN_TYPE.TEXT
        },
        {
            text: 'text.logo',
            key: 'logo',
            width: 160,
            noSort: true,
            type: COLUMN_TYPE.TEXT,
            render: (text, record) => (
                <>
                    <wrap>
                        <div style={{
                            /*backgroundImage: "Url(https://plateforme-services.com/uploads/normes/" + record.logo + ")",*/
                            backgroundImage: "Url(" + record.image_url + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        height: "35px"
                        }} />
                    </wrap>
                </>
            ),
        },
    ];

    useEffect(() => {
        viewRef.current = true;

        return () => { viewRef.current = false;  }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const updateNormes = useCallback(() => {
        const updatedKeys = selectedRows.filter(r=>disabledRows.indexOf(r) === -1)
        Ajax.post({
            url: ApiUrl.FamilyNormeUpdate,
            data: {
                 "famille_uuid": uuid, "keys": updatedKeys
            },
            success: function (response) {
                if (response && viewRef.current) {
                    if (!response.HasError) {
                        message.success(<T>message.normes_have_been_successfully_updated</T>);
                    } else {
                        message.error(response.message)
                    }
                }
            }
        })
    }, [uuid, selectedRows, disabledRows]);

    return (<>
        <ListView
            {...props}
            isSimple
            skipEdit
            noPaging
            skipNewButton
            columnConfig={columnConfig}
            apiUrl={ApiUrl.FamilyNormeList}
            //search={{ field: 'search' }}
            staticFilter={{ famille_uuid: uuid }}
            tableLayout="Fixed"
            rowSelection={{
                selectedRowKeys: selectedRows,
                onChange: (selectedRowKeys) => {
                    setSelectedRows(selectedRowKeys);
                },
                getCheckboxProps: record => ({
                    disabled: record.disabled > 0,
                    // Column configuration not to be checked
                    name: record.key,
                })
            }}
            onLoaded={(data, sorts) => { // NOSONAR

                let selectedKeys = data.map((r) => { r.key = r.uuid; return r }).filter((r) => r.norme_uuid !== "" || r.disabled > 0).map((r) => r.key);
                let disabledKeys = data.map((r) => { r.key = r.uuid; return r }).filter((r) => r.disabled > 0).map((r) => r.key);
                setSelectedRows(selectedKeys);
                setDisabledRows(disabledKeys);

                return data;
            }}
            rowClassName={record => { // NOSONAR
                return record.disabled ? "disabled" : ""; // NOSONAR
            }}
        />
        <toolbar>
            <wrap>
                <action right=''>
                    <div className="button" onClick={() => updateNormes()}><icon>save</icon><T>text.save</T></div>
                </action>
            </wrap>
        </toolbar>
    </>);
};