import { T } from 'components/Translations';
import { ApiUrl, AppPages, UserRoles } from 'project/Defines.js';
import { Project } from 'project/Project';
import { dispatchCustomEvent, EVENT_NAME } from 'project/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import DetailView from 'Shared/DetailView';
import DropDownValueList from 'Shared/DropDown';
import { FormField } from 'Shared/Form/formFields.js';
import { TabStrip } from 'Shared/TabStrip';
import useDetail from 'Shared/useDetail';
import { Ajax } from '../../components/Ajax';
import { ValidationInput } from '../../Shared/Form/validation';
import SocieteClubStatus from './ClubStatus';
import SocieteSalesNetwork from './SalesNetwork';
import SocieteSkills from './SocieteSkills';
import SocieteUser from './SocieteUser';
import SocieteTechnisian from './SocieteTechnician';


const validationFields = [
    { name: 'siret', rules: [{ type: "regexp", regexp: /^\S*$/, message: "text.invalid_siret" }]},
    { name: "type_code", rules: [{ type: "number" }] },
    { name: "nature_code", rules: [{ type: "number" }] },
    { name: "email", rules: [{ type: "email" }] },
    'livraison_telephone'
];


/**
 * the UserDetails main tab containing tabstrip layout
 * @exports UserDetails
 */
function UserDetails(props) {
    const { id } = useParams();
    const tab = new URLSearchParams(props.location.search).get("tab");
    const { windowKey, data, role } = props;
    const [tabIndex, setTabIndex] = useState(parseInt(tab) || 0);
    const [accessUtilList, setAccessUtilList] = useState([]);
    const [accessFunctionalList, setAccessFunctionalList] = useState([]);
    const listUpdateEvent = EVENT_NAME.USER_LIST_UPDATE;
    const history = useHistory();
    useEffect(() => {
        if (!isNew && !tab && id !== "create")
            setTabIndex(0);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (tab !== tabIndex) { // NOSONAR
            history.push(Project.getPageUrl(AppPages.UserDetails, id, { tab: tabIndex }));
        }
    }, [tabIndex]);// eslint-disable-line react-hooks/exhaustive-deps

    const detailProps = useDetail({
        data: data || { ID: id !== "create" ? id : null },
        updateUrl: ApiUrl.UserUpdate,
        windowKey,
        dataLoadUrl: ApiUrl.UserLoad,
        nameField: 'raison_sociale',
        deleteUrl: ApiUrl.UserDelete,
        backPage: AppPages.User,
        listUpdateEvent,
        detailUrl: Project.getPageUrl(AppPages.UserDetails),
        validationFields
    });
    const { isNew, dataItem, setDataItem, updateData, onChangeData, closePopup, detailRef, validation } = detailProps;

    useEffect(() => {
        Ajax.post({
            url: ApiUrl.ValueList,
            data: {
                valueList: 'acces_util',
            },
            success: function (response) {
                if (response && detailRef.current) {
                    const data = response;
                    setAccessUtilList(data);
                }
            }
        });
        Ajax.post({
            url: ApiUrl.ValueList,
            data: {
                valueList: 'acces_functional',
            },
            success: function (response) {
                if (response && detailRef.current) {
                    const data = response;
                    setAccessFunctionalList(data);
                }
            }
        });
    }, [detailRef]);

    const updateSuccessCallback = useCallback((response) => {
        if (detailRef.current) {
            setDataItem(response);
        }

        dispatchCustomEvent(listUpdateEvent, { action: 'update' });
        closePopup();
    }, [setDataItem, closePopup, detailRef, listUpdateEvent]);

    const onLivraisonChangeCallback = useCallback((event, newData) => {
        if (newData.livraison_idem_facturation) {
            newData.facturation_adresse = dataItem.livraison_adresse;
            newData.facturation_adresse_complement = dataItem.livraison_adresse_complement;
            newData.facturation_code_postal = dataItem.livraison_code_postal;
            newData.facturation_ville = dataItem.livraison_ville;
            newData.facturation_pays = dataItem.livraison_pays;
        }
    }, [dataItem]);

    const onChangeDataWithCallback = useCallback(e => {
        onChangeData(e, onLivraisonChangeCallback);
    }, [onLivraisonChangeCallback, onChangeData]);

    const onAccessChange = useCallback((event) => {
        let newData = { ...dataItem };
        let acces_uuids = dataItem.acces_uuids || [];
        if (event.target.checked) {
            if (!acces_uuids.includes(event.target.name)) {
                acces_uuids.push(event.target.name);
            }
        } else {
            let index = acces_uuids.indexOf(event.target.name);
            if (index !== -1) {
                acces_uuids.splice(index, 1);
            }
        }
        setDataItem({ ...newData, acces_uuids });
    }, [setDataItem, dataItem]);

    const tabFilter = useCallback((tab) => { return ( tab.props.noSATC && role !== UserRoles.SATC) || (tab.props.noDispatcher && role !== UserRoles.DISPATCHER) || tab.props.all; }, [role]);
    const isReadOnly = dataItem && dataItem.pro_account && props.settings && !props.settings?.ID360IsDisabled;

    return (<>
        {dataItem && <DetailView
            backUrl={Project.getPageUrl(AppPages.User)}
            title={isNew ? <T>text.new_user</T> : dataItem.raison_sociale}
        >
            <form>
                <div className='form_container'> <TabStrip onChange={(selected) => { // NOSONAR
                    setTabIndex(selected);
                }
                } skipSecondaryTabs={isNew} selectedIndex={tabIndex} tabFilter={tabFilter}>
                    <div title='text.main' primary all>
                        <div className='section_group'>
                            <div className='sections '>
                                <FormField>
                                    <div className="section_title"><text><T>text.main</T></text></div>
                                    <div className="form_separator" ></div>
                                </FormField>
                                <FormField label="text.siret_intern">
                                    <ValidationInput validation={validation} name='siret' type='text' autoComplete="off" maxLength="40" value={dataItem.siret} onChange={onChangeData} readOnly={isReadOnly} disabled={isReadOnly} />
                                </FormField>
                                <FormField label="text.name">
                                    <input name='raison_sociale' type='text' autoComplete="off" maxLength="240" value={dataItem.raison_sociale} onChange={onChangeData} />
                                </FormField>

                                <FormField label="text.telephone">
                                    <input name='telephone' type='text' autoComplete="off" maxLength="40" value={dataItem.telephone} onChange={onChangeData} />
                                </FormField>
                                <FormField label="text.fax">
                                    <input name='fax' type='text' autoComplete="off" maxLength="40" value={dataItem.fax} onChange={onChangeData} />
                                </FormField>
                                <FormField label="text.site_web">
                                    <input name='site_web' type='text' autoComplete="off" maxLength="240" value={dataItem.site_web} onChange={onChangeData} />
                                </FormField>
                                <FormField label="text.nature_code">
                                    <ValidationInput validation={validation} name='nature_code' type='text' autoComplete="off" maxLength="40" value={dataItem.nature_code || ''} onChange={onChangeData} />
                                </FormField>
                                <FormField label="text.type_code">
                                    <ValidationInput validation={validation} name='type_code' type='text' autoComplete="off" maxLength="40" value={dataItem.type_code || ''} onChange={onChangeData} />
                                </FormField>
                                <FormField label="text.code_adherent">
                                    <input name='code_adherent' type='text' autoComplete="off" maxLength="40" value={dataItem.code_adherent} onChange={onChangeData} />
                                </FormField>
                                <FormField label="text.code_station">
                                    <input name='code_station' type='text' autoComplete="off" maxLength="40" value={dataItem.code_station} onChange={onChangeData} />
                                </FormField>
                                <div className='form_fields'>
                                    <label checkbox=''><input name='interne' type='checkbox' checked={dataItem.interne} onChange={onChangeData} /><box><check></check></box><text><T>text.interne</T></text></label>
                                    <label checkbox=''><input name='connectivity_blocking' type='checkbox' checked={dataItem.connectivity_blocking} readOnly /><box><check></check></box><text><T>text.connectivity_blocking</T></text></label>
                                </div>
                            </div>
                            <div className='sections '>
                                <FormField>
                                    <div className="section_title"><text><T>text.delivery</T></text></div>
                                    <div className="form_separator"></div>
                                </FormField>
                                <FormField label="text.address">
                                    <input name='livraison_adresse' type='text' autoComplete="off" maxLength="240" value={dataItem.livraison_adresse} onChange={onChangeDataWithCallback} />
                                </FormField>
                                <FormField label="text.address_complement">
                                    <input name='livraison_adresse_complement' autoComplete="off" type='text' maxLength="240" value={dataItem.livraison_adresse_complement} onChange={onChangeDataWithCallback} />
                                </FormField>
                                <FormField label="text.code_postal">
                                    <input name='livraison_code_postal' type='text' autoComplete="off" maxLength="40" value={dataItem.livraison_code_postal} onChange={onChangeDataWithCallback} />
                                </FormField>
                                <FormField label="text.city">
                                    <input name='livraison_ville' type='text' autoComplete="off" maxLength="240" value={dataItem.livraison_ville} onChange={onChangeDataWithCallback} />
                                </FormField>
                                <FormField label="text.country">
                                    <DropDownValueList
                                        name='livraison_pays'
                                        value={dataItem.livraison_pays}
                                        valuelist='pays'
                                        onChange={e => onChangeDataWithCallback({ target: { name: 'livraison_pays', value: e } })} // NOSONAR
                                        showSearch
                                        searchOptions={{
                                            noAccent: true,
                                            contains: true
                                        }} />
                                </FormField>
                                <FormField label="text.telephone">
                                    <ValidationInput validation={validation} name='livraison_telephone' type='text' autoComplete="off" maxLength="40" value={dataItem.livraison_telephone} onChange={onChangeDataWithCallback} />
                                </FormField>
                            </div>
                            <div className='sections '>
                                <FormField>
                                    <div className="section_title"><text><T>text.facturation</T></text></div>
                                    <div className="form_separator"></div>
                                </FormField>
                                <FormField>
                                    <label checkbox=''><input name='livraison_idem_facturation' type='checkbox' defaultChecked={dataItem.livraison_idem_facturation} onChange={onChangeDataWithCallback} /><box><check></check></box><text><T>text.copy_generalities</T></text></label>
                                </FormField>
                                <FormField label="text.address">
                                    <input name='facturation_adresse' type='text' autoComplete="off" maxLength="240" value={dataItem.facturation_adresse} onChange={onChangeData} disabled={dataItem.livraison_idem_facturation} />
                                </FormField>
                                <FormField label="text.address_complement">
                                    <input name='facturation_adresse_complement' type='text' autoComplete="off" maxLength="240" value={dataItem.facturation_adresse_complement} onChange={onChangeData} disabled={dataItem.livraison_idem_facturation} />
                                </FormField>
                                <FormField label="text.code_postal">
                                    <input name='facturation_code_postal' type='text' autoComplete="off" maxLength="40" value={dataItem.facturation_code_postal} onChange={onChangeData} disabled={dataItem.livraison_idem_facturation} />
                                </FormField>
                                <FormField label="text.city">
                                    <input name='facturation_ville' type='text' autoComplete="off" maxLength="240" value={dataItem.facturation_ville} onChange={onChangeData} disabled={dataItem.livraison_idem_facturation} />
                                </FormField>
                                <FormField label="text.country">
                                    <DropDownValueList name='facturation_pays'
                                        value={dataItem.facturation_pays} valuelist='pays'
                                        onChange={e => onChangeData({ target: { name: 'facturation_pays', value: e } })} // NOSONAR
                                        disabled={dataItem.livraison_idem_facturation}
                                        showSearch
                                        searchOptions={{
                                            noAccent: true,
                                            contains: true
                                        }} />
                                </FormField>
                            </div>
                            <div className='sections '>
                                <FormField>
                                    <div className="section_title"><text><T>text.part_confiance</T></text></div>
                                    <div className="form_separator"></div>
                                </FormField>
                                <FormField label="text.code_azur">
                                    <input name='part_confiance_code_azur' type='text' autoComplete="off" maxLength="40" value={dataItem.part_confiance_code_azur} onChange={onChangeData} />
                                </FormField>
                                <FormField >
                                    <label checkbox=''><input name='part_confiance_site_web' type='checkbox' defaultChecked={dataItem.part_confiance_site_web} onChange={onChangeData} /><box><check></check></box><text><T>text.url_website_visible</T></text></label>
                                </FormField>
                                {/*<FormField >*/}
                                {/*    <label checkbox=''><input name='subcontractor' type='checkbox' defaultChecked={dataItem.subcontractor} onChange={onChangeData} /><box><check></check></box><text><T>text.subcontractor</T></text></label>*/}
                                {/*</FormField>*/}
                            </div>
                            <div className='sections medium'>
                                <FormField>
                                    <div className="section_title"><text><T>text.comments</T></text></div>
                                    <div className="form_separator" ></div>
                                </FormField>
                                <FormField>
                                    <textarea name='commentaire_outils' autoComplete="off" note="" large_note="" value={dataItem.commentaire_outils} onChange={onChangeData} />
                                </FormField>
                            </div>
                            <div className='sections medium'>
                                <FormField>
                                    <div className="section_title"><text><T>text.email</T></text></div>
                                    <div className="form_separator" ></div>
                                </FormField>
                                <FormField label="text.email">
                                    <ValidationInput validation={validation} name='email' type='text' autoComplete="off" maxLength="240" value={dataItem.email || ''} onChange={onChangeData} />
                                </FormField>
                            </div>
                        </div>
                        <div className='section_group'>
                            <div className='sections '>
                                <div className="form_fields"><div className="section_title"><text><T>text.utilities</T></text></div><div className="form_separator"></div></div>
                            </div>
                        </div>
                        <div className='section_group'>

                            {[1, 2, 3, 4].map((majorIndex) => {
                                let culumnCount = Math.min(accessUtilList.length, 4);
                                return <div className='sections' key={'checkSection' + majorIndex}>
                                    {accessUtilList.filter((acc, i) => i + 1 <= Math.ceil(majorIndex / culumnCount * accessUtilList.length) && i + 1 > Math.ceil((majorIndex - 1) / culumnCount * accessUtilList.length))
                                        .map(ac =>
                                            <FormField key={ac.value}>
                                                <label checkbox=''><input name={ac.value} type='checkbox' checked={dataItem.acces_uuids && dataItem.acces_uuids.length && (dataItem.acces_uuids.includes(ac.value) || false)} onChange={onAccessChange} /><box><check></check></box><text>{ac.text}</text></label>
                                            </FormField>
                                        )}
                                </div>;
                            }
                            )}
                        </div>
                        <div className='section_group'>
                            <div className='sections '>
                                <div className="form_fields"><div className="section_title"><text><T>text.functionalities</T></text></div><div className="form_separator"></div></div>
                            </div>
                        </div>
                        <div className='section_group'>
                            {[1, 2, 3, 4].map((majorIndex) => {
                                let culumnCount = Math.min(accessFunctionalList.length, 4);
                                return <div className='sections' key={'checkSection' + majorIndex}>
                                    {accessFunctionalList.filter((acc, i) => i + 1 <= Math.floor(majorIndex / culumnCount * accessFunctionalList.length) && i + 1 > Math.ceil((majorIndex - 1) / culumnCount * accessFunctionalList.length))
                                        .map(ac =>
                                            <FormField key={ac.value}>
                                                <label checkbox=''><input name={ac.value} type='checkbox' checked={dataItem.acces_uuids && dataItem.acces_uuids.length && (dataItem.acces_uuids.includes(ac.value) || false)} onChange={onAccessChange} /><box><check></check></box><text>{ac.text}</text></label>
                                            </FormField>
                                        )}
                                </div>;
                            }
                            )}
                        </div>
                    </div>
                    <div title='text.users' all>
                        <SocieteUser {...dataItem} />
                    </div>
                    <div title='text.sales_networks' all>
                        <SocieteSalesNetwork {...dataItem} />
                    </div>
                    <div title='text.clubs' noDispatcher>
                        <SocieteClubStatus {...detailProps} />
                    </div>
                    <div title='text.skills' noSATC>
                        <SocieteSkills {...dataItem} />
                    </div>
                    {/*<div title='text.postal_code'>*/}
                    {/*    <SocietePostalCode {...detailProps} />*/}
                    {/*</div>*/}
                    
                    <div title='text.station_technicians' noSATC>
                        <SocieteTechnisian {...dataItem} />
                    </div>
                </TabStrip>
                </div>
                {[0].includes(tabIndex) &&
                    <toolbar>
                        <wrap>
                            <action right=''>
                                <div onClick={(e) => updateData(updateSuccessCallback)} className='button'><icon>save</icon><T>text.save</T></div>
                            </action>
                        </wrap>
                    </toolbar>}
            </form>
        </DetailView>}
    </>);
}



export default connect(state => ({ role: state.role, settings: state.settings }))(UserDetails);